import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import { HTMLContent } from '../components/Content';
import BookingForm from '../components/BookingForm';
import UnsupportedBrowser from '../components/UnsupportedBrowser';
import isBrowserSupported from '../isBrowserSupported';

export const BookingPageTemplate = ({ content }) => (
  <div className="bookingPage">
    <div className="bookingPage__text">
      <HTMLContent content={content} />
    </div>

    <div className="bookingPage__form" id="bookingForm">
      <BookingForm />
    </div>
  </div>
);

export default ({ data, location }) => {
  const { markdownRemark: page, site } = data;

  return (
    <Layout location={location}>
      <Helmet
        title={`${site.siteMetadata.title} | ${page.frontmatter.title}`}
      />
      {isBrowserSupported() ? (
        <BookingPageTemplate content={page.html} />
      ) : (
        <UnsupportedBrowser />
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query BookingPageQuery($path: String!) {
    markdownRemark(frontmatter: { urlPath: { eq: $path } }) {
      html
      frontmatter {
        title
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;
