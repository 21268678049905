import { detect } from 'detect-browser';

const isBrowserSupported = () => {
  const browser = detect();

  if (browser && browser.name === 'ie' && parseInt(browser.version, 10) < 10) {
    return false;
  }

  return true;
};

export default isBrowserSupported;
