import React from 'react';

import DateRangePicker from 'react-daterange-picker';
import moment from 'moment';

import 'moment/locale/cs';

moment.locale('cs');

const stateDefinitions = {
  available: {
    color: null,
    label: 'Volno',
  },
  lowAvailability: {
    color: '#ffd200',
    label: 'Nízká dostupnost',
  },
  unavailable: {
    selectable: false,
    color: '#78818b',
    label: 'Obsazeno',
  },
};

const dateStates = [];

const DatePicker = ({ onDatesChange, dateRange }) => (
  <div>
    <DateRangePicker
      locale="cs"
      firstOfWeek={1}
      selectionType="range"
      minimumDate={new Date()}
      stateDefinitions={stateDefinitions}
      dateStates={dateStates}
      defaultState="available"
      onSelect={onDatesChange}
      value={dateRange}
      selectedLabel="Váš termín"
    />

    <input type="hidden" name="startDate" />
    <input type="hidden" name="endDate" />
  </div>
);

export default DatePicker;
