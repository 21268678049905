import React, { Component } from 'react';

export default class StepIncrementer extends Component {
  constructor(props) {
    super(props);

    this.increase = this.increase.bind(this);
    this.descrease = this.descrease.bind(this);
  }

  increase() {
    this.props.onChange(this.props.value + 1);
  }

  descrease() {
    this.props.onChange(this.props.value - 1);
  }

  render() {
    return (
      <div className="stepIncrementer btn-group">
        <button
          className="stepIncrementer__button btn btn-secondary"
          type="button"
          onClick={this.descrease}
        >
          -
        </button>

        <button
          type="button"
          className="stepIncrementer__value btn btn-light"
          disabled={true}
        >
          {this.props.value}
        </button>

        <button
          className="stepIncrementer__button btn btn-secondary"
          type="button"
          onClick={this.increase}
        >
          +
        </button>
      </div>
    );
  }
}
