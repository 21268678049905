import React from 'react';

import StepIncrementer from './StepIncrementer';

const Guests = ({ adults, children, onAdultsChange, onChildrenChange }) => (
  <div className="guests">
    <input type="hidden" name="adults" value={adults} />
    <input type="hidden" name="children" value={children} />

    <div className="guests__row row">
      <div className="col-6">Dospělí</div>
      <div className="col-6">
        <StepIncrementer value={adults} onChange={onAdultsChange} />
      </div>
    </div>

    <div className="guests__row row">
      <div className="col-6">
        Děti
        <br />
        <small>Věk do 10 Let</small>
      </div>
      <div className="col-6">
        <StepIncrementer value={children} onChange={onChildrenChange} />
      </div>
    </div>
  </div>
);

export default Guests;
