import moment from 'moment';
import first from 'lodash/first';

export const INDIVIDUAL_PRICE_CUTOFF = 10; // days
export const SINGLE_APPARTMENT_PERSON_EXTRA = 550; // CZK
export const CHILDREN_PER_NIGHT = 450; // CZK
export const SPA_FEE_PER_PERSON_DAY = 35; // CZK

const createDate = str => moment(str, 'DD.MM.');

export const PRICES = {
  1: 700,
  2: 650,
  3: 650,
  4: 650,
  more: 550,
};

export const DISCOUNTED_PRICES = {
  1: 650,
  2: 580,
  3: 580,
  4: 580,
  more: 550,
};

export const DISCOUNTED_PERIODS = [
  {
    fromDate: createDate('1.1.'),
    toDate: createDate('18.6.'),
    prices: DISCOUNTED_PRICES,
  },
  {
    fromDate: createDate('1.9.'),
    toDate: createDate('31.12.'),
    prices: DISCOUNTED_PRICES,
  },
];

export const getPricesForDate = (date, discountedPeriods) => {
  if (!discountedPeriods) {
    discountedPeriods = DISCOUNTED_PERIODS;
  }

  const filterPeriod = period => {
    const fromDate = period.fromDate.clone().year(1970);
    const toDate = period.toDate.clone().year(1970);
    const currentDate = date.clone().year(1970);

    const isAfter = currentDate.isSameOrAfter(fromDate);
    const isBefore = currentDate.isSameOrBefore(toDate);

    return isAfter && isBefore;
  };

  const matchedDiscount = first(discountedPeriods.filter(filterPeriod));

  let prices = PRICES;

  if (matchedDiscount) {
    prices = matchedDiscount.prices;
  }

  return prices;
};

export const calculateDayPrice = (date, totalNights, adults, children) => {
  const prices = getPricesForDate(date);
  const pricePerNight = prices[totalNights] ? prices[totalNights] : prices.more;

  let adultsSum = pricePerNight * adults + SPA_FEE_PER_PERSON_DAY * adults;

  if (adults + children === 1) {
    adultsSum += SINGLE_APPARTMENT_PERSON_EXTRA;
  }

  const childrenSum = children * CHILDREN_PER_NIGHT;

  return adultsSum + childrenSum;
};

export const calculateNights = dateRange => {
  return dateRange.end.diff(dateRange.start, 'days');
};

export const calculatePrice = (dateRange, adults, children) => {
  const totalNights = calculateNights(dateRange);

  const nightsDateRange = dateRange.clone();
  nightsDateRange.end.subtract(1, 'day');

  let finalPrice = 0;
  for (let date of nightsDateRange.by('days')) {
    const dayPrice = calculateDayPrice(date, totalNights, adults, children);
    finalPrice += dayPrice;
  }

  return finalPrice;
};

export default calculatePrice;
