import React, { Component } from 'react';
import $ from 'jquery';
import classNames from 'classnames';
import isEmail from 'is-email';
import TextArea from 'react-textarea-autosize';
import pick from 'lodash/pick';
import Link, { navigateTo } from 'gatsby-link';

import DatePicker from './DatePicker';
import Guests from './Guests';

import calculatePrice, { calculateNights } from '../prices';

export default class BookingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adults: 1,
      children: 0,
      dateRange: null,
      showCustomerDetails: false,
      submitting: false,

      customerName: '',
      customerEmail: '',
      customerPhone: '',
      customerNote: '',
    };

    this.handleAdultsChange = this.handleAdultsChange.bind(this);
    this.handleChildrenChange = this.handleChildrenChange.bind(this);
    this.handleDatesChange = this.handleDatesChange.bind(this);
    this.handleShowCustomerDetails = this.handleShowCustomerDetails.bind(this);
    this.handleCustomerInputChange = this.handleCustomerInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (window && window.localStorage) {
      [
        'customerName',
        'customerEmail',
        'customerPhone',
        'customerNote',
      ].forEach(name => {
        this.setState({
          [name]: window.localStorage.getItem(name) || '',
        });
      });
    }
  }

  handleAdultsChange(adults) {
    if (adults < 1) return;
    this.setState({
      adults,
    });
  }

  handleChildrenChange(children) {
    if (children < 0) return;
    this.setState({
      children,
    });
  }

  handleDatesChange(dateRange) {
    if (!dateRange) {
      return;
    }

    const days = dateRange.end.diff(dateRange.start, 'day');

    if (days <= 1) {
      return;
    }

    this.setState({
      dateRange,
    });
  }

  handleShowCustomerDetails(event) {
    event.preventDefault();
    this.setState({
      showCustomerDetails: true,
    });
  }

  handleCustomerInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (window && window.localStorage) {
      window.localStorage.setItem(name, value);
    }

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitting: true });

    const data = pick(this.state, [
      'adults',
      'children',
      'customerEmail',
      'customerName',
      'customerPhone',
      'customerNote',
    ]);

    data.startDate = this.state.dateRange.start.format('DD. MM. YYYY');
    data.endDate = this.state.dateRange.end.format('DD. MM. YYYY');
    data.nights = calculateNights(this.state.dateRange);
    data.price = this.getPrice();

    $.ajax({
      url: `/.netlify/functions/booking`,
      method: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(data),
    })
      .done(() => navigateTo('/rezervace/odeslana'))
      .fail(jqXHR =>
        alert(`Vyskytla se neočekávaná chyba: ${jqXHR.responseText}`),
      )
      .always(() => this.setState({ submitting: false }));
  }

  getPrice() {
    return calculatePrice(
      this.state.dateRange,
      this.state.adults,
      this.state.children,
    );
  }

  isSubmitDisabled() {
    return (
      this.state.submitting ||
      !this.state.customerName ||
      !isEmail(this.state.customerEmail) ||
      !this.state.dataProcessingAgreed
    );
  }

  renderPrice() {
    const price = this.getPrice();

    if (price && price.toLocaleString) {
      return `${price.toLocaleString('cs-CZ')},-`;
    }

    return `${price},-`;
  }

  renderResults() {
    if (!this.state.dateRange) {
      return <div className="noDateRangeNotice"> Začněte výběrem termínu </div>;
    }

    const totalNights = calculateNights(this.state.dateRange);

    return (
      <div className="bookingForm__result">
        <div className="row">
          <div className="col-4 font-weight-bold"> Termín </div>
          <div className="col-8 text-right">
            {this.state.dateRange
              ? this.state.dateRange.start.format('D. M. YYYY')
              : null}
            <span> – </span>
            {this.state.dateRange
              ? this.state.dateRange.end.format('D. M. YYYY')
              : null}
          </div>
        </div>
        <div className="row">
          <div className="col-8 font-weight-bold"> Počet nocí </div>
          <div className="col-4 text-right"> {totalNights} </div>
        </div>
        <div className="row">
          <div className="col-8 font-weight-bold"> Dospělí </div>
          <div className="col-4 text-right"> {this.state.adults} </div>
        </div>
        <div className="row">
          <div className="col-8 font-weight-bold"> Děti </div>
          <div className="col-4 text-right"> {this.state.children} </div>
        </div>
        <div className="row border-top font-weight-bold pt-3 mt-3">
          <div className="col-4"> Cena </div>
          <div className="col-8 text-right"> {this.renderPrice()} </div>
        </div>
      </div>
    );
  }

  renderBookingFields() {
    return (
      <div
        className={classNames({
          bookingForm__customerDetails: true,
          'mt-3': true,
          'd-none': !this.state.showCustomerDetails,
        })}
      >
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <h3> Poptávka </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 offset-md-2">
            <div className="form-group required">
              <label htmlFor="customerName" className="control-label">
                Jméno a příjmení
              </label>
              <input
                type="text"
                id="customerName"
                name="customerName"
                value={this.state.customerName}
                onChange={this.handleCustomerInputChange}
                className={classNames({
                  'form-control': true,
                  'is-invalid': !this.state.customerName,
                })}
                required={true}
              />
              <div className="invalid-feedback"> Jméno je povinné </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group required">
              <label htmlFor="customerEmail" className="control-label">
                E - mail
              </label>
              <input
                type="email"
                id="customerEmail"
                name="customerEmail"
                value={this.state.customerEmail}
                onChange={this.handleCustomerInputChange}
                className={classNames({
                  'form-control': true,
                  'is-invalid': !isEmail(this.state.customerEmail),
                })}
                required={true}
              />
              <div className="invalid-feedback"> E - mail je povinný </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 offset-md-2">
            <div className="form-group">
              <label htmlFor="customerPhone"> Telefon </label>
              <input
                type="text"
                id="customerPhone"
                name="customerPhone"
                value={this.state.customerPhone}
                onChange={this.handleCustomerInputChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <label htmlFor="note"> Poznámka </label>

            <TextArea
              id="note"
              name="customerNote"
              value={this.state.customerNote}
              onChange={this.handleCustomerInputChange}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="dataProcessingAgreed"
                onChange={this.handleCustomerInputChange}
                id="dataProcessingAgreed"
              />
              <label
                className="form-check-label"
                htmlFor="dataProcessingAgreed"
              >
                Souhlasím se zpracováním mých údajů pouze pro účely komunikace a
                zprotředkování poptávky.&nbsp;
                <Link to="/zasady-ochrany-osobnich-udaju" target="blank">
                  Zásady ochrany osobních údajů
                </Link>
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-8 offset-md-2">
            <button
              className="btn btn-primary btn-block"
              type="submit"
              disabled={this.isSubmitDisabled()}
            >
              ODESLAT POPTÁVKU
            </button>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 col-md-8 offset-md-2 small">
            Podle zákona o evidenci tržeb je prodávající povinen vystavit
            kupujícímu účtenku. Zároveň je povinen zaevidovat přijatou tržbu u
            správce daně online; v případě technického výpadku pak nejpozději do
            48 hodin.
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <form
          className="bookingForm__form"
          name="booking"
          onSubmit={this.handleSubmit}
        >
          <div className="row">
            <div className="col-12 col-md-4">
              <h4> Termín </h4>
              <DatePicker
                dateRange={this.state.dateRange}
                onDatesChange={this.handleDatesChange}
              />
            </div>
            <div className="col-12 col-md-4">
              <h4> Hosté </h4>
              <Guests
                adults={this.state.adults}
                children={this.state.children}
                onAdultsChange={this.handleAdultsChange}
                onChildrenChange={this.handleChildrenChange}
              />
            </div>
            <div className="col-12 col-md-4">
              <h4> Kalkulace </h4> {this.renderResults()}
            </div>
          </div>
          {this.state.dateRange && !this.state.showCustomerDetails && (
            <div className="row mt-3">
              <div className="col-12 offset-md-8 col-md-4">
                <div className="bookingForm__resultActions">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={this.handleShowCustomerDetails}
                  >
                    POPTAT
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.renderBookingFields()}
        </form>
      </div>
    );
  }
}
