import React from 'react';
import Link from 'gatsby-link';

const UnsupportedBrowser = () => (
  <div className="unsupportedBrowser">
    Váš prohlížeč je zřejmě zastaralý a rezervace online tak nepůjde odeslat.
    <br />
    Kontaktujte nás prosím
    <Link to="/kontakt"> telefonicky nebo e-mailem</Link>.
  </div>
);

export default UnsupportedBrowser;
